Reset default styles
body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
}

/* AppBar styles */
.MuiAppBar-root {
  width: 100vw !important;
}

.MuiToolbar-root {
  width: 100% !important;
}

/* Amplify Authenticator styles */
.amplify-authenticator {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 28rem;
  margin: 0 auto;
  padding: 1rem;
}

/* Form elements */
.amplify-button[data-variation='primary'] {
  background: #E67E22;
  padding: 0.6rem 2.5rem;
}

.amplify-button[data-variation='primary']:hover {
  background: #D35400;
}

.amplify-tabs {
  border: none;
}

.amplify-tabs-item[data-state='active'] {
  border-color: #E67E22;
  color: #E67E22;
}

.amplify-tabs-item:hover {
  color: #D35400;
}

.amplify-field__control:focus {
  border-color: #E67E22;
}

/* Mobile responsiveness */
@media (max-width: 600px) {
  .amplify-authenticator {
      padding: 0.5rem;
  }

  .amplify-button {
      min-height: 44px;
  }

  .amplify-field__control {
      min-height: 44px;
      font-size: 16px;
  }
}

/* Transitions */
.amplify-button,
.amplify-field__control,
.amplify-tabs-item {
  transition: all 0.2s ease-in-out;
}